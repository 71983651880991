import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="messages"
export default class extends Controller {
  connect(): void {
    this.#scrollToBotton();
    this.#makeDatesVisible();
  }

  disconnect(): void {
    clearTimeout(this.#timeoutId);
  }

  #timeoutId?: NodeJS.Timeout;

  #scrollToBotton(): void {
    const contentPadding = 158;
    this.#timeoutId = setTimeout(() => {
      window.scrollTo(
        0,
        document.documentElement.scrollHeight + contentPadding
      );
    }, 100);
  }

  #makeDatesVisible(): void {
    const allDates: HTMLElement[] = Array.from(
      document.querySelectorAll('.chat-date')
    ) as HTMLElement[];
    const datesToBeShown: HTMLElement[] = this.#groupDates(allDates);
    datesToBeShown.forEach((element) => {
      element.style.display = 'inline';
    });
  }

  #groupDates(spans: HTMLElement[]): HTMLElement[] {
    const groups = new Map<string, HTMLElement>();

    for (const span of spans) {
      const text = span.textContent?.trim();
      if (!text) continue;
      const date = text.split(',')[0];

      if (!groups.has(date)) {
        groups.set(date, span);
      }
    }

    return Array.from(groups.values());
  }
}
