import { Controller } from '@hotwired/stimulus';
import { jsbridge } from '../lib/jsbridge';
declare global {
  interface HTMLVideoElement {
    webkitExitFullscreen?: () => void;
    exitFullscreen?: () => void;
    controlsList?: string;
  }
}

// Connects to data-controller="exercise"
export default class extends Controller {
  static targets = ['button'];
  static values = { url: String };
  declare urlValue: string;
  declare buttonTarget: HTMLButtonElement;

  #_videoTag: HTMLVideoElement | null = null;

  async start(): Promise<void> {
    if (jsbridge.enabled) {
      await jsbridge.call('playVideo', { url: this.urlValue });
      return;
    }
    const tag = this.#videoTag;
    tag.src = this.urlValue;
    tag.onwaiting = () => {
      this.buttonTarget.classList.add('button--loading');
    };
    tag.onplaying = () => {
      this.buttonTarget.classList.remove('button--loading');
    };
    tag.play();
    tag.hidden = false;
    tag.requestFullscreen();
  }

  get #videoTag(): HTMLVideoElement {
    if (this.#_videoTag) return this.#_videoTag;
    const element = document.createElement('video');
    element.controlsList = 'nodownload';
    element.controls = true;
    element.style.width = '100vw';
    element.style.height = '100vh';
    element.style.top = '0';
    element.style.position = 'absolute';
    element.addEventListener('ended', () => {
      this.removeVideoElement(element);
    });
    element.addEventListener('fullscreenchange', () => {
      this.removeVideoElement(element);
    });
    element.addEventListener('webkitendfullscreen', () => {
      this.removeVideoElement(element);
    });
    document.body.prepend(element);
    this.#_videoTag = element;
    return element;
  }

  disconnect(): void {
    this.#_videoTag?.remove();
  }

  removeVideoElement(element: HTMLVideoElement): void {
    if (!document.fullscreenElement) {
      element.remove();
    }
  }
}
