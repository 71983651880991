import { Controller } from '@hotwired/stimulus';
import { deviceReady } from '../lib/time';

interface Store {
  PAID_SUBSCRIPTION: string;
  validator?: string;
  register(products: Array<{ id: string; type: string }>): void;
  error(callback: (error: Error) => void): void;
  when(type: string): Store;
  approved(callback: (product: Product) => void): Store;
  verified(callback: (product: Product) => void): Store;
  finished(callback: (product: Product) => void): Store;
  updated(callback: (product: Product) => void): Store;
  refresh(): void;
  order(id: string): void;
  get(id: string): Product | null;
}

interface Product {
  finish(): void;
  verify(): void;
  price?: string;
  priceMicros?: number;
  currency?: string;
  state: 'finished';
  transaction?: {
    appStoreReceipt: string;
  };
}

interface PurchaselyObject {
  isReadyToPurchase(a: boolean): void;
  presentPresentationForPlacement(
    placement: string,
    content: null,
    fullscreen: boolean,
    success?: (res: { result: number }) => void,
    error?: (error: Error) => void
  ): void;
  startWithAPIKey(
    key: string,
    stores: ['Google'], // list of stores for Android, accepted values: Google, Huawei and Amazon
    userId: string | null,
    logLevel: string,
    runningMode: string
  ): void;
  LogLevel: { DEBUG: string };
  RunningMode: { full: string };
}

declare const Purchasely: PurchaselyObject;

declare global {
  interface Window {
    Purchasely: PurchaselyObject;
  }
}

declare const store: Store;
function usePurchasely(): boolean {
  return !!window.Purchasely;
}

async function preload(): Promise<void> {
  if (!usePurchasely()) {
    store.validator =
      'https://validator.fovea.cc/v1/validate?appName=com.dreamsleep.eaze&apiKey=4a66e3cf-3bb7-471c-997c-4b5e2e860d0e';
    store.register([
      {
        id: '1',
        type: store.PAID_SUBSCRIPTION,
      },
      {
        id: '2',
        type: store.PAID_SUBSCRIPTION,
      },
    ]);
    store.error((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
    store.refresh();
  }
}
deviceReady().then(preload);

// Connects to data-controller="purchase"
export default class extends Controller {
  static targets = ['price1', 'price1Input', 'price3', 'receipt', 'button'];
  declare readonly price1Target: HTMLDivElement;
  declare readonly price1InputTarget: HTMLInputElement;
  declare readonly price3Target: HTMLDivElement;
  declare readonly receiptTarget: HTMLInputElement;
  declare readonly buttonTarget: HTMLButtonElement;

  static values = {
    userId: String,
  };
  declare userIdValue: string;

  async connect(): Promise<void> {
    await deviceReady();
    if (usePurchasely()) {
      await Purchasely.startWithAPIKey(
        'a908c91a-9137-41b5-bc28-79ea9d3ad92a',
        ['Google'],
        this.userIdValue,
        Purchasely.LogLevel.DEBUG,
        Purchasely.RunningMode.full
      );
      Purchasely.isReadyToPurchase(true);
      Purchasely.presentPresentationForPlacement(
        'onboarding',
        null,
        true,
        (subscription) => {
          if (subscription.result !== 1) {
            this.receiptTarget.form?.requestSubmit();
          }
        }
      );
    } else {
      store
        .when('subscription')
        .updated(() => {
          const prod3 = store.get('2');
          const prod1 = store.get('1');
          if (prod3) {
            this.price3Target.innerText = this.#formatPrice(
              prod3.priceMicros ?? 0,
              12,
              prod3.currency
            );
          }
          if (prod1) {
            this.price1Target.innerText = this.#formatPrice(
              prod1.priceMicros ?? 0,
              4,
              prod1.currency
            );
          }
        })
        .approved((p) => {
          p.verify();
        })
        .verified((p) => {
          p.finish();
        })
        .finished((p) => {
          if (p.transaction) {
            this.receiptTarget.value = p.transaction.appStoreReceipt;
            this.receiptTarget.form?.requestSubmit();
            this.buttonTarget.classList.remove('button--loading');
          }
        });
      store.refresh();
    }
  }

  async confirm(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    this.buttonTarget.classList.add('button--loading');
    const id = this.price1InputTarget.checked ? '1' : '2';
    store.order(id);
  }

  #formatPrice(price: number, weeks: number, currency?: string): string {
    price = price * 0.000001 * (1.0 / weeks);
    const priceString = new Intl.NumberFormat('de', {
      style: 'currency',
      currency: currency ?? 'EUR',
    }).format(price);
    return `${priceString} / Woche`;
  }
}
