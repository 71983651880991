import { Controller } from '@hotwired/stimulus';
import { serializeForm } from '../lib/form';

// Connects to data-controller="continue-button"
export default class extends Controller {
  static values = {
    emptyLabel: String,
  };
  declare emptyLabelValue: string;
  originalValue!: string;

  async connect(): Promise<void> {
    this.originalValue = (this.element as HTMLInputElement).value;
    this.updateButton();
    (this.element as HTMLInputElement).form?.addEventListener('input', () =>
      this.updateButton()
    );
  }

  private updateButton(): void {
    const button = this.element as HTMLInputElement;
    const data = serializeForm(button.form as HTMLFormElement);
    const step = data.find(([key]) => key === 'sleep_diary_entry[step]')?.[1];
    if (!step) return;
    const empty = !data
      .filter((e) => e[0].includes(step))
      .map((e) => e[1])
      .join('\n');

    button.classList.toggle('button--skip', empty);
    button.value = empty ? this.emptyLabelValue : this.originalValue;
  }
}
