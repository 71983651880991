import { Controller } from '@hotwired/stimulus';
import { wait } from '../lib/time';

// Connects to data-controller="delayed-click"
export default class extends Controller {
  static values = {
    delay: Number,
  };
  declare delayValue: number;

  async connect(): Promise<void> {
    await wait(this.delayValue);
    (this.element as HTMLLinkElement).click();
  }
}
