import { Controller } from '@hotwired/stimulus';
import { Idfa } from '@sparkfabrik/capacitor-plugin-idfa';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';

async function getAdvertisingId(): Promise<string | null> {
  const info = await Idfa.getAdvertisingInfo();
  return info.id;
}

async function requestPermission(): Promise<void> {
  if (Capacitor.isPluginAvailable('AppTrackingTransparency')) {
    const response = await AppTrackingTransparency.getStatus();
    if (response.status === 'notDetermined') {
      await AppTrackingTransparency.requestPermission();
    }
  }
}

// Connects to data-controller="tracking"
export default class extends Controller {
  static targets = ['advertising', 'vendor'];
  declare advertisingTarget: HTMLInputElement;
  declare vendorTarget: HTMLInputElement;

  async connect(): Promise<void> {
    await requestPermission();
    const adId = (await getAdvertisingId()) ?? '';
    const vendorId = await (await Device.getId()).uuid;
    if (
      adId === this.advertisingTarget.value &&
      vendorId === this.vendorTarget.value
    ) {
      return;
    }
    this.advertisingTarget.value = adId;
    this.vendorTarget.value = vendorId;
    this.advertisingTarget.form?.requestSubmit();
  }
}
