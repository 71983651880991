import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="length-counter"
export default class extends Controller {
  static targets = ['counter', 'input'];

  declare readonly counterTarget: HTMLSpanElement;
  declare readonly inputTarget: HTMLInputElement;

  update(): void {
    const maxCharacters = this.inputTarget.maxLength;
    const characterCount = this.inputTarget.value.length;

    this.counterTarget.textContent = `${characterCount}/${maxCharacters}`;
  }
}
