// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Payload = Record<string, any>;

type Callback = (payload: Payload) => void;

interface BridgeEvent {
  id: string;
  name: string;
  payload: Payload;
}

export function randomId(): string {
  const array = new Uint32Array(10);
  crypto.getRandomValues(array);
  return array.join();
}

class JSBridge {
  callbacks: Record<string, Callback> = {};
  eventCallbacks: Record<string, Callback[]> = {};

  emit({ id = 'no-id', name, payload = {} }: BridgeEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).webkit?.messageHandlers._bridgeEventCallback.postMessage({
      id,
      name,
      payload,
    });
  }

  on(event: string, callback: Callback): void {
    this.eventCallbacks[event] = this.eventCallbacks[event] || [];
    this.eventCallbacks[event].push(callback);
  }

  get enabled(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!(window as any).webkit?.messageHandlers._bridgeEventCallback;
  }

  private receive({ id, name, payload }: BridgeEvent): void {
    this.callbacks[id]?.(payload);
    this.eventCallbacks[name]?.forEach((e) => e(payload));
  }

  call(method: string, payload: Payload = {}): Promise<Payload> {
    const id = randomId();
    this.emit({ id, name: method, payload });
    return new Promise((res) => {
      this.callbacks[id] = res;
    });
  }
}
export const jsbridge = new JSBridge();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).jsbridge = jsbridge;
