import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="autogrow"
export default class extends Controller {
  static targets = ['input', 'box', 'submit'];
  declare inputTarget: HTMLTextAreaElement;
  declare boxTarget: HTMLDivElement;
  declare submitTarget: HTMLButtonElement;

  tabbar = document.querySelector('.tabbar') as HTMLElement | null;

  connect(): void {
    this.inputTarget.addEventListener('focus', this.#toggleTabbar);
    this.inputTarget.addEventListener('focusout', this.#toggleTabbar);
  }

  disconnect(): void {
    this.inputTarget.removeEventListener('focus', this.#toggleTabbar);
    this.inputTarget.removeEventListener('focusout', this.#toggleTabbar);
  }

  resize(): void {
    this.inputTarget.style.height = '';
    this.inputTarget.style.height =
      Math.min(this.inputTarget.scrollHeight, 302) + 'px';
  }

  #toggleTabbar: () => void = () => {
    if (this.tabbar === null) return;

    this.tabbar.classList.toggle('tabbar--hidden');
    this.boxTarget.classList.toggle('chat-input--hidden');
  };
}
