import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  textValue!: string;

  static values = {
    text: String,
  };

  async share(): Promise<void> {
    try {
      this.element.classList.add('loading');
      await navigator.share({ text: this.textValue });
    } finally {
      this.element.classList.remove('loading');
    }
  }
}
