import { Controller } from '@hotwired/stimulus';
import { jsbridge, randomId } from '../lib/jsbridge';

// Connects to data-controller="navbar"
export default class extends Controller {
  private id = randomId();
  static values = {
    icon: String,
  };
  declare iconValue?: string;

  connect(): void {
    const title = (this.element as HTMLAnchorElement).innerText;
    jsbridge.call('addNavbarButton', {
      title,
      id: this.id,
      icon: this.iconValue || null,
    });
    jsbridge.on('navbar-button-clicked', (payload) => {
      if (payload.id === this.id) {
        (this.element as HTMLAnchorElement).click();
      }
    });
  }
}
