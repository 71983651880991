import { Controller } from '@hotwired/stimulus';
import {
  PushNotifications,
  PermissionStatus,
} from '@capacitor/push-notifications';
import {
  NativeSettings,
  IOSSettings,
  AndroidSettings,
} from 'capacitor-native-settings';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { jsbridge } from '../lib/jsbridge';

if (Capacitor.isPluginAvailable('PushNotifications')) {
  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (error) => {
    // eslint-disable-next-line no-console
    console.error('Error on registration: ' + JSON.stringify(error));
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    // eslint-disable-next-line no-console
    console.log('Push received: ' + JSON.stringify(notification));
  });

  // Method called when tapping on a notification
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification) => {
      // eslint-disable-next-line no-console
      console.log('Push action performed: ' + JSON.stringify(notification));
    }
  );
}

// Connects to data-controller="push"
export default class extends Controller {
  static targets = [
    'token',
    'statusUndetermined',
    'statusConfirmed',
    'statusDenied',
  ];
  declare readonly tokenTarget: HTMLInputElement;
  declare readonly statusUndeterminedTarget: HTMLDivElement;
  declare readonly statusConfirmedTarget: HTMLDivElement;
  declare readonly statusDeniedTarget: HTMLDivElement;

  async connect(): Promise<void> {
    PushNotifications.addListener('registration', (token) => {
      this.tokenTarget.value = token.value;
      this.tokenTarget.form?.requestSubmit();
    });
    this.#refresh();
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.#refresh();
      }
    });
  }

  async register(): Promise<void> {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      PushNotifications.requestPermissions().then((result) => {
        this.#update(result);
      });
    } else {
      const { token } = await jsbridge.call('requestPushToken');
      this.tokenTarget.value = token;
      this.tokenTarget.form?.requestSubmit();
    }
  }

  openSettings(): void {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  }

  async #refresh(): Promise<void> {
    const result = await PushNotifications.checkPermissions();
    this.#update(result);
  }

  async #update(result: PermissionStatus): Promise<void> {
    this.statusConfirmedTarget.classList.toggle(
      'hidden',
      result.receive !== 'granted'
    );
    this.statusDeniedTarget.classList.toggle(
      'hidden',
      result.receive !== 'denied'
    );
    this.statusUndeterminedTarget.classList.toggle(
      'hidden',
      result.receive !== 'prompt'
    );
    if (result.receive === 'granted') {
      PushNotifications.register();
    }
  }
}
