import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="phone"
export default class extends Controller {
  static targets = ['button', 'input'];
  declare buttonTarget: HTMLButtonElement;
  declare inputTarget: HTMLInputElement;

  connect(): void {
    this.inputTarget.addEventListener('input', this.#change);
    this.inputTarget.addEventListener('keypress', (event) =>
      this.#disableSubmit(event)
    );
    this.#updateDisableButton(true);
  }

  disconnect(): void {
    this.inputTarget.removeEventListener('input', this.#change);
    this.inputTarget.removeEventListener('keypress', (event) =>
      this.#disableSubmit(event)
    );
    this.#updateDisableButton(false);
  }

  #change: () => void = () => {
    if (this.inputTarget.value.length > 0) this.#updateDisableButton(false);

    if (this.inputTarget.value.length === 4) {
      this.#updateDisableButton(true);
      this.inputTarget.form?.requestSubmit();
    }
  };

  #disableSubmit: (event: KeyboardEvent) => void = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.preventDefault();
      return false;
    }
  };

  #updateDisableButton: (value: boolean) => void = (value) => {
    this.buttonTarget.disabled = value;
  };
}
