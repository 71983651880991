import { Controller } from '@hotwired/stimulus';
import {
  SignInWithApple,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

// Connects to data-controller="apple-login"
export default class extends Controller {
  static targets = ['code'];

  declare readonly codeTarget: HTMLInputElement;

  async login(): Promise<void> {
    const options: SignInWithAppleOptions = {
      clientId: 'com.dreamsleep.eaze',
      redirectURI: '',
      scopes: 'email name',
      state: '',
      nonce: 'nonce',
    };

    try {
      const result = await SignInWithApple.authorize(options);
      this.codeTarget.value = result.response.authorizationCode;
      this.codeTarget.form?.requestSubmit();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
