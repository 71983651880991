export function wait(seconds: number): Promise<void> {
  return new Promise((res) => {
    setTimeout(res, seconds * 1000);
  });
}

const deviseReadyPromises: Array<() => void> = [];
let deviceIsReady = false;
function onDeviceReady(): void {
  deviceIsReady = true;
  deviseReadyPromises.forEach((e) => e());
}
document.addEventListener('deviceready', onDeviceReady);

export function deviceReady(): Promise<void> {
  return new Promise((res) => {
    if (deviceIsReady) {
      res();
    } else {
      deviseReadyPromises.push(res);
    }
  });
}
