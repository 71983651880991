import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="video"
export default class extends Controller {
  static targets = ['video', 'control', 'skip'];
  declare videoTarget: HTMLMediaElement;
  declare skipTarget: HTMLButtonElement;
  declare controlTargets: HTMLDivElement[];

  connect(): void {
    this.videoTarget.addEventListener('ended', () => {
      this.skipTarget.click();
    });
  }

  togglePlay(): void {
    const el = this.videoTarget as HTMLMediaElement;
    if (el.paused) {
      el.play();
      this.controlTargets.forEach((e) => e.classList.add('hidden'));

      setTimeout(() => {
        this.skipTarget.classList.remove('hidden');
      }, 5000);
    } else {
      el.pause();
      this.controlTargets.forEach((e) => e.classList.remove('hidden'));
    }
  }
}
