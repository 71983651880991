import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="slider"
export default class extends Controller {
  connect(): void {
    this.element.addEventListener('input', this.#update.bind(this));
  }

  disconnect(): void {
    this.element.removeEventListener('input', this.#update.bind(this));
  }

  #update(): void {
    const value = parseFloat(this.#el.value) * 0.01;
    const quadrant = Math.floor(value * 4 * 0.99); // make sure it never reaches 100%
    this.#el.dataset.quadrant = String(quadrant);
  }

  get #el(): HTMLInputElement {
    return this.element as HTMLInputElement;
  }
}
