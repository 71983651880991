import { Application } from '@hotwired/stimulus';
import { installErrorHandler } from '@appsignal/stimulus';
import Appsignal from '@appsignal/javascript';
import { plugin } from '@appsignal/plugin-window-events';

const application = Application.start();

const env = document.body.classList[0];

if (env === 'production') {
  const appsignal = new Appsignal({
    key: 'bef94da8-359d-46d6-957b-a9de89b155eb',
    namespace: 'frontend',
  });
  appsignal.use(plugin({ onerror: true, onunhandledrejection: true }));

  installErrorHandler(appsignal, application);
}

application.debug = false;

export { application };
