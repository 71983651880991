import { Controller } from '@hotwired/stimulus';
import { jsbridge } from '../lib/jsbridge';

// Connects to data-controller="exercise"
export default class extends Controller {
  static targets = ['content'];
  declare contentTargets: HTMLDivElement[];

  connect(): void {
    jsbridge.on('native-search-updated', ({ term }) => {
      term = term.toLocaleLowerCase();
      this.contentTargets.forEach((target) => {
        const match = target.innerText.toLocaleLowerCase().includes(term);
        target.hidden = !match;
      });
    });
  }
}
